
import { defineComponent, ref, unref, toRefs, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';

import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import { getBanner, IntBoolean, uploadVideo } from '@/services/api';

import { useCreateBanner, useUpdateBanner, useBanner } from '@/composables/api';
import { PartialBanner, BannerType, BannerPosition, BannerImageRatio, BannerMobileImageRatio } from '@/interfaces/Banner';

const DEFAULT_FORM_VALUES: PartialBanner = {
  position: BannerPosition.HOME,
  name: '',
  title: '',
  subtitle: '',
  type: BannerType.IMAGE,
  image: '',
  mobileImage: '',
  video: '',
  mobileVideo: '',
  link: '',
  sort: 1,
  expiredAt: ''
};

const rules: Rules = {
  position: [
    {
      required: true
    }
  ],
  name: [
    {
      required: true
    }
  ],
  type: [
    {
      required: true
    }
  ],
  image: [
    {
      required: true
    }
  ],
  mobileImage: [
    {
      required: true
    }
  ],
  link: [
    {
      type: 'url'
    }
  ],
  sort: [
    {
      required: true
    }
  ]
};

const POSITION_OPTIONS = Object
  .entries(BannerPosition)
  .map(([label, value]) => ({ label, value }));

const TYPE_OPTIONS = Object
  .entries(BannerType)
  .map(([label, value]) => ({ label, value }));

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const bannerId = useRoute().params.id as string;
    const router = useRouter();

    const page = useRoute().query.page;

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });

    const { isLoading: isCreatedLoading, mutate: create } = useCreateBanner();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateBanner();
    const { formErrors, bindFormItemError } = useFormErrors();

    // reload detail
    const { data, dataUpdatedAt } = useBanner({ bannerId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleImageCrop = ({ path }: UploadImageEvent) => {
      formValues.value.image = path;
    };

    const handleMobileImageCrop = ({ path }: UploadImageEvent) => {
      formValues.value.mobileImage = path;
    };
    /**
     * video
     */
    const videoList = ref([]);
    watch(() => formValues.value.video, (video) => {
      videoList.value = video
        ? [{ name: video }]
        : [];
    });
    const handleVideoChange = async({ raw }: UploadFile) => {
      const { data } = await uploadVideo({ data: { video: raw } });
      formValues.value.video = data.path;
    };
    const handleVideoRemove = () => {
      formValues.value.video = '';
    };
    /**
     * mobileVideo
     */
    const mobileVideoList = ref([]);
    watch(() => formValues.value.mobileVideo, (mobileVideo) => {
      mobileVideoList.value = mobileVideo
        ? [{ name: mobileVideo }]
        : [];
    });

    const handleMobileVideoChange = async({ raw }: UploadFile) => {
      const { data } = await uploadVideo({ data: { video: raw } });
      formValues.value.mobileVideo = data.path;
    };

    const handleMobileVideoRemove = () => {
      formValues.value.mobileVideo = '';
    };
    /**
   * 當 Position 為 plans 則image比例為 192x19
   */
    const imageRatio = ref(BannerImageRatio.DEFAULT);
    const mobileImageRatio = ref(BannerMobileImageRatio.DEFAULT);
    watch(() => formValues.value.position, (position) => {
      imageRatio.value = position === BannerPosition.PLANS
        ? BannerImageRatio.PLANS_RATIO : BannerImageRatio.DEFAULT;
    }, { immediate: true });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);
      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { bannerId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-banners'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;

                  router.push({
                    name: 'list-banners'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      IntBoolean,
      handleImageCrop,
      handleMobileImageCrop,
      handleVideoChange,
      videoList,
      handleVideoRemove,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      BannerType,
      POSITION_OPTIONS,
      TYPE_OPTIONS,
      formErrors,
      bindFormItemError,
      mobileVideoList,
      handleMobileVideoChange,
      handleMobileVideoRemove,
      imageRatio,
      mobileImageRatio
    };
  }
});
